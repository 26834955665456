import React from 'react';

import {
    useState,
} from 'react';

import {
    Avatar,
    Box,
    IconButton,
    Link,
    Tooltip,
    Typography,
    useMediaQuery,


Button,
Dialog,
DialogActions,
DialogContent,
DialogContentText,
DialogTitle,
Slide,

} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import PlaceIcon from '@material-ui/icons/Place';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventIcon from '@material-ui/icons/Event';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import {
    merge as lodashMerge
} from 'lodash';





//
// Effet de transition ('slide' du bas vers le haut) utilisé pour
// faire apparaître la boîte de dialogue 'DialogCalendarShare'
//
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



//
// Style associée à la boite de dialogue 'DialogCalendarShare'
//
const useDialogCalendarShareStyles = makeStyles((theme) => ({
    dialogCalendarShareDialogContent: {
        display: 'flex',
        flexDirection: 'column',
    },
    dialogCalendarShareDialogContentButton: {
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'rgb(255, 255, 255)',
        '&:hover': {
            color: 'rgb(255, 255, 255)',
            textDecoration: 'none',
        }
    },
}));



//
// Boite de dialogue utilisée pour le téléchargement d'un fichier '.ics'
//
const DialogCalendarShare = ({ open, handleClose, ics, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useDialogCalendarShareStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-labelledby="dialog-calendar-share-title"
            aria-describedby="dialog-calendar-share-description"
        >
            <DialogTitle id="dialog-calendar-share-title">Ajouter au calendrier</DialogTitle>
            <DialogContent className={classes.dialogCalendarShareDialogContent}>
                <DialogContentText id="dialog-calendar-share-description">
                    Pour ajouter cet événement à votre calendrier, cliquez sur le bouton ci-dessous et enregistrer le fichier sur votre ordinateur.
                    Ensuite, ouvrez votre agenda et importez le fichier.
                </DialogContentText>

                <Button
                    variant="contained"
                    color="primary"
                    className={classes.dialogCalendarShareDialogContentButton}
                    startIcon={<CloudDownloadIcon />}
                    component={Link}
                    href={`data:text/calendar;charset=UTF-8;method=PUBLISH;name=${ics.name},${encodeURIComponent(ics.card)}`}
                    download={ics.name}
                >
                    Télécharger le fichier .ics
                </Button>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentHappeningDetailTitle: style => ({
        ...style.contentHappeningDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailTitle.xl,
        },
    }),

    contentHappeningDetailRoot: style => ({
        ...style.contentHappeningDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailRoot.xl,
        },
    }),

    contentHappeningDetailHeaderImg: style => ({
        ...style.contentHappeningDetailHeaderImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailHeaderImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailHeaderImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailHeaderImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailHeaderImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailHeaderImg.xl,
        },
    }),

    contentHappeningDetailShareToolbar: style => ({
        ...style.contentHappeningDetailShareToolbar.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailShareToolbar.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailShareToolbar.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailShareToolbar.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailShareToolbar.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailShareToolbar.xl,
        },
    }),

    contentHappeningDetailShareAvatar: style => ({
        ...style.contentHappeningDetailShareAvatar.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailShareAvatar.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailShareAvatar.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailShareAvatar.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailShareAvatar.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailShareAvatar.xl,
        },
    }),

    contentHappeningDetailPlace: style => ({
        ...style.contentHappeningDetailPlace.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailPlace.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailPlace.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailPlace.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailPlace.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailPlace.xl,
        },
    }),

    contentHappeningDetailDate: style => ({
        ...style.contentHappeningDetailDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailDate.xl,
        },
    }),

    contentHappeningDetailDescription: style => ({
        ...style.contentHappeningDetailDescription.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailDescription.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailDescription.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailDescription.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailDescription.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailDescription.xl,
        },
    }),

    contentHappeningDetailSignatureDate: style => ({
        ...style.contentHappeningDetailSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentHappeningDetailSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentHappeningDetailSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentHappeningDetailSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentHappeningDetailSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentHappeningDetailSignatureDate.xl,
        },
    }),

}));



//
// Composant permettant d'afficher le détail d'une manifestation
//
export const ContentHappeningDetail = ({ title, params, style, data, ...props }) => {

    // Actuellement, deux modes d'affichage sont disponbiles :
    // > md : mode 'desktop' (grand écran)
    // <= md : mode 'mobile'
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));



  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };





    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentHappeningDetailTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailRoot: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        borderRadius: '0px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        backgroundColor: '#fff',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        borderRadius: '4px',
                    },
                    lg: {
                        borderRadius: '4px',
                    },
                    xl: {
                        borderRadius: '4px',
                    },
                },
                contentHappeningDetailHeaderImg: lodashMerge(
                    {
                        common: {
                            width: '100%',
                            height: '100%',
                            maxHeight: '50vh',
                            objectFit: 'cover',
                        },
                        xs: {},
                        sm: {},
                        md: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                        lg: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                        xl: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                    },
                    (data && data.img_header && data.img_header.style) || {},
                ),
                contentHappeningDetailShareToolbar: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailShareAvatar: {
                    common: {
                        color: theme.palette.getContrastText(theme.palette.primary.main),
                        backgroundColor: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailPlace: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        gap: '0.5rem',
                        alignItems: 'center',
                        marginTop: '1rem',
                        marginLeft: '1.5rem',
                        fontSize: '0.9rem',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailDate: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        gap: '0.5rem',
                        alignItems: 'center',
                        marginTop: '1rem',
                        marginLeft: '1.5rem',
                        fontSize: '0.9rem',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailDescription: {
                    common: {
                        padding: '1.5rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentHappeningDetailSignatureDate: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginRight: '1.5rem',
                        marginBottom: '1.5rem',
                        fontSize: '0.9rem',
                        fontStyle: 'italic',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mise en forme des dates
    let str_date_start = data.date_start && date_fns_format(parseISO(data.date_start), 'EEEE dd MMMM yyyy', {locale: fr});
    let str_date_end = data.date_end && date_fns_format(parseISO(data.date_end), 'EEEE dd MMMM yyyy', {locale: fr});
    let str_date_happening = "";
    if(str_date_start) {
        if(str_date_end) {
            if(str_date_start === str_date_end) {
                str_date_happening = `Le ${str_date_start}`;
            } else {
                str_date_happening = `Du ${str_date_start} au ${str_date_end}`;
            }
        } else {
            str_date_happening = `À partir du ${str_date_start}`;
        }
    } else {
        str_date_happening = "Date de l'événement non précisé"
    }


    return (
        <>
            {data.title &&
                <Typography
                    className={classes.contentHappeningDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {data.title}
                </Typography>
            }

            <Box
                className={classes.contentHappeningDetailRoot}
            >
                {data.img_header && data.img_header.src &&
                    <img
                        className={classes.contentHappeningDetailHeaderImg}
                        src={data.img_header.src}
                        srcSet={data.img_header.srcset}
                        sizes={isMDUp ? "75vw" : "100vw"}
                        title={`${data.img_header.legend} - ${data.img_header.credits}`}
                        alt={data.img_header.alt}
                    />
                }


                <Box
                    className={classes.contentHappeningDetailShareToolbar}
                >
                    {data.ics_card &&
                        <Tooltip
                            id="happening-share-calendar"
                            title="Ajouter au calendrier"
                        >
                            <IconButton
                                aria-labelledby="happening-share-calendar"
                                color="primary"
                                onClick={handleClickOpen}
                            >
                                <Avatar className={classes.contentHappeningDetailShareAvatar}>
                                    <EventIcon />
                                </Avatar>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>


                <Box className={classes.contentHappeningDetailPlace}>
                    <PlaceIcon className={classes.icon} />
                    <Box componant="span">
                        {data.place}
                    </Box>
                </Box>

                <Box className={classes.contentHappeningDetailDate}>
                    <ScheduleIcon className={classes.icon} />
                    <Box componant="span">
                        {str_date_happening}
                    </Box>
                </Box>

                <Box
                    className={classes.contentHappeningDetailDescription}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                />

                <Box className={classes.contentHappeningDetailSignatureDate}>
                    <Box componant="span">
                        {data.signature}
                    </Box>
                    <Box componant="span">
                        Publié le {data.date_publication_start && date_fns_format(parseISO(data.date_publication_start), 'EEEE dd MMMM yyyy', {locale: fr})}
                    </Box>
                </Box>
            </Box>


            <DialogCalendarShare
                open={open}
                handleClose={handleClose}
                ics={{
                    name: data.ics_name,
                    card: data.ics_card,
                }}
            />
        </>
    );
}
